let Backbone = require('backbone');
let _ = require('underscore');
require('./../class/Ribs');



let Line = Backbone.Ribs.Model.extend({
    // Было так, но в таком варианте не работало this.listenTo(this.model, 'change:scope', this.changeForSiS)
    //scope: {
    //    identifier: undefined,
    //    value: undefined,
    //    start: undefined,
    //    end: undefined
    //}
    defaults: {
        parameter: undefined,
        subparameter: undefined,
        selected: false,
        numberFound: undefined,
        identifier: undefined,
        value: undefined,
        start: undefined,
        end: undefined
    },

    initialize: function (attributes, parameters) {
        this.parameters = parameters;
    },


    searchStop: function () {
        this.parameters.cTables.last().set('disabled', false);
        this.parameters.mSiS.set('trackChangesInLine', true);
    },


    // undefined - model.parameter не существует
    // true -  существует подпараметр
    // false - не существует подпараметра
    isDataInSubparameter: function() {
        let parameter = this.get('parameter');
        return _.isUndefined(parameter) ? undefined : !_.isUndefined(parameter.subParameters);
    },


    // Возвращает все актуальные элементы у параметра, доставая их или из parameter или из subParameter,
    // в зависимости от ситуации. Возвращает false, если model.parameter не существует
    getActualParam: function() {
        let isDataInSubparameter = this.isDataInSubparameter();
        if (_.isUndefined(isDataInSubparameter)) return false;
        let parameter = this.get('parameter');
        let subParameter = this.get('subparameter');
        let isSubParameter = !_.isUndefined(subParameter);
        let actualParam = {
            isDataInSubparameter: isDataInSubparameter, // Есть ли впринципе SubParameter
            isSubparameter: isSubParameter,
            description: parameter.description,         // только у parameter
            type: undefined,                            // либо у parameter либо у subParameter
            select: undefined                           // либо у parameter либо у subParameter
        };
        if (isSubParameter) {
            actualParam.infoParent = parameter.info;
            actualParam.info = subParameter.info;
            actualParam.type = subParameter.type;
            if (actualParam.type == 'select') actualParam.select = subParameter.select;
        } else if (!isDataInSubparameter) {
            actualParam.info = parameter.info;
            actualParam.type = parameter.type;
            if (actualParam.type == 'select') actualParam.select = parameter.select;
        }
        return actualParam;
    }

});



module.exports = Line;