let _ = require('underscore');
let Backbone = require('backbone');
let Line = require('./../model/Line');


let Lines = Backbone.Collection.extend({
    model: Line,

    initialize: function (collection, parameters) {
        this.parameters = parameters;
        this.listenTo(this, 'remove', this.changeForSiS);
        this.listenTo(this, 'add', this.changeForSiS);
    },

    changeForSiS: function() {
        let numberChanges = this.parameters.mSiS.get('numberChanges') + 1;
        this.parameters.mSiS.set('numberChanges', numberChanges);
    }
});


module.exports = Lines;