// Класс описывает ячейку "Значение"
let _ = require('underscore');



class Scope {
    constructor() {
        this.scope = [
            {
                identifier: 'value',
                scope: ['text', 'number', 'date', 'select'],
                title: 'Значение',
                icon: 'input'
            },
            {
                identifier: 'exclude',
                scope: ['text', 'number', 'date', 'select'],
                title: 'Исключить',
                icon: 'close'
            },
            {
                identifier: 'from',
                scope: ['number', 'date'],
                title: 'От',
                icon: 'keyboard_arrow_left'
            },
            {
                identifier: 'to',
                scope: ['number', 'date'],
                title: 'До',
                icon: 'keyboard_arrow_right'
            },
            {
                identifier: 'diapason',
                scope: ['number', 'date'],
                title: 'Диапазон',
                icon: 'settings_ethernet'
            },
            {
                identifier: 'forday',
                scope: ['date'],
                title: 'За сегодня',
                icon: 'filter_1'
            },
            {
                identifier: 'forweek',
                scope: ['date'],
                title: 'За неделю',
                icon: 'filter_7'
            },
            {
                identifier: 'formonth',
                scope: ['date'],
                title: 'За месяц',
                icon: 'grid_on'
            },
            {
                identifier: 'foryear',
                scope: ['date'],
                title: 'За год',
                icon: 'date_range'
            }
        ]
    }


    getAllText() {
        return this.getAll('text');
    }


    getAllNumber() {
        return this.getAll('number');
    }


    getAllDate() {
        return this.getAll('date');
    }


    getAllSelect() {
        return this.getAll('select');
    }


    getAll(typeScope) {
        let data = [];
        _.each(this.scope, function(el) {
            if (el.scope.indexOf(typeScope) !== -1) {
                data.push(el);
            }
        });
        return data;
    }


    getScope(typeScope, identifier) {
        return _.find(this.scope, function(el){
            return (el.scope.indexOf(typeScope) !== -1) && (el.identifier == identifier);
        });
    }

}



module.exports = Scope;