let $ = require('jquery');
let _ = require('underscore');
let Backbone = require('backbone');
let Utils = require('./../class/Utils');
let Tooltip = require('./../class/Tooltip');
var TemParam = require('./../template/parameter.tpl');



let Parameter = Backbone.View.extend({
    initialize: function() {
        this.listenTo(this.model, 'change:parameter', this.render);
    },


    // У select событие click в софарике работает как-то странно
    events: {
        'change .bbds-select-parameter': 'changeParameter',
        'change .bbds-select-subparameter': 'changeSubparameter'
    },


    changeParameter: function(event) {
        let option = $(event.target).find('option:selected');
        let param = this.getParameterByIndex(option.val(), option.data('index-in-optgroup'));
        this.model.set('parameter', param);
        this.model.set('subparameter', undefined);
    },


    changeSubparameter: function() {
        let i = this.$('.bbds-select-subparameter').val();
        let subParameters = this.model.get('parameter.subParameters');
        this.model.set('subparameter', subParameters[i]);
    },


    getParameterByIndex: function(outerIndex, optgroupIndex) {
        let param = this.model.parameters[outerIndex];
        if (!_.isUndefined(optgroupIndex)) param = param.parameters[optgroupIndex];
        return param;
    },


    render: function() {
        Tooltip($);
        let actualParam = this.model.getActualParam();
        this.$el.html(Utils.template(TemParam, {
            parameters: this.model.parameters,
            parameter: this.model.get('parameter'),
            subparameter: this.model.get('subparameter'),
            indParameter: _.indexOf(this.model.parameters, this.model.get('parameter')),
            actualParam: actualParam,
            disabledTable: this.model.collection.disabled
        }));
        this.$('[data-toggle="tooltip"]').tooltip();
        return this;
    }
});



module.exports = Parameter;