let _ = require('underscore');
let Backbone = require('backbone');
let Table = require('./../model/Table');
let MLine = require('./../model/Line');



let Tables = Backbone.Collection.extend({
    model: Table,


    initialize: function (collection, parameters) {
        this.parameters = parameters;
        this.listenTo(this.parameters.mSiS, 'change:resetSelection', this.resetSelection);
    },


    resetSelection: function() {
        this.each(function(mTbl) {
            mTbl.set('selected', false);
            let lines = mTbl.get('lines');
            lines.each(function(mLn) {
                mLn.set('selected', false);
            }, this);
        }, this);
    },


    setNumberFound: function(indexTable, cidLine, number) {
        let table = this.at(indexTable);
        let line;
        if (table) line = table.get('lines').get(cidLine);
        if (line)  line.set('numberFound', number);
        return !!line;
    },


    // При вызове функции без параметра не изменяется предыдущее значение numberFoundForTable, такое поведение используется при
    // запросе по строке, чтобы оставить общее число найденных элементов без изменений. Запрос по всему фильтру
    // как правило используется с numberFoundForTable, без этого значения невозможно будет осуществить "поиск в поиске".
    searchStop: function(numberFoundForTable) {
        if (!this.parameters.options.disabled) {
            this.last().set('disabled', false);
        }
        if (numberFoundForTable || numberFoundForTable === 0) {
            this.last().set('numberFound', numberFoundForTable);
            // Если предыдущий поиск дал результат в N  и текущий так же N, то перерисовки интерфейса не будет, а если
            // в результате изменений в фильтре исчезла кнопка "поиск в поиске", то она так и не появится, поэтому
            // нужно сначало поставить null.
            this.parameters.mSiS.set('numberFound', null);
            this.parameters.mSiS.set('numberFound', numberFoundForTable);
        }
        // Т.к. любое изменение numberFound приводит к пересмотру и изменению blocking, то присвоение blocking
        // должно быть в самом конце. blocking - показывать или нет кнопку "Поиск среди найденного"
        if (this.last().get('lines').length <= 1) {
            this.parameters.mSiS.set('blocking', true);
        }
        this.parameters.mSiS.set('trackChangesInLine', true);
    },


    generateSearchObject: function() {
        let searchQuery = [];
        let parameters = this.parameters;
        this.each(function(mTbl, indexTable) {
            let resultLines = [];
            let lines = mTbl.get('lines');
            _.each(_.clone(lines.models), function(mLn) {
                let param = mLn.get('parameter');
                let subParam = mLn.get('subparameter');

                let idParam = { title: param ? param.title : false };
                if (subParam) idParam.subTitle = subParam.title;

                let identifier = mLn.get('identifier');
                let resultLine = {
                    cid: mLn.cid,
                    type: identifier,
                    idParam: idParam
                };
                let actualParam = mLn.getActualParam();
                if (actualParam.info) resultLine.info = actualParam.info;
                if (actualParam.infoParent) resultLine.infoParent = actualParam.infoParent;

                let value = mLn.get('value');
                let start = mLn.get('start');
                let end = mLn.get('end');
                if (resultLine && identifier) {
                    switch (identifier) {
                        case 'value':
                            if (value) resultLine.value = value;
                            else resultLine = false;
                            break;
                        case 'exclude':
                            if (value) resultLine.exclude = value;
                            else resultLine = false;
                            break;
                        case 'from':
                            if (start) resultLine.start = start;
                            else resultLine = false;
                            break;
                        case 'to':
                            if (end) resultLine.end = end;
                            else resultLine = false;
                            break;
                        case 'diapason':
                            setDiapason(start, end, resultLine);
                            break;
                        case 'forday':
                            if (value) resultLine.value = value;
                            else resultLine = false;
                            break;
                        case 'forweek':
                            setDiapason(start, end, resultLine);
                            break;
                        case 'formonth':
                            setDiapason(start, end, resultLine);
                            break;
                        case 'foryear':
                            setDiapason(start, end, resultLine);
                            break;
                        default: resultLine = false;
                    }
                } else resultLine = false;
                if (resultLine) {
                    resultLines.push(resultLine);
                } else mLn.destroy();
            });
            searchQuery.push(resultLines);
            // новая строчка в конце
            if (indexTable === this.length - 1) lines.add(new MLine({}, parameters));
        }, this);

        function setDiapason(start, end, resultLine) {
            if (start || end) {
                if (start) resultLine.start = start;
                if (end) resultLine.end = end;
            } else resultLine = false;
        }

        return searchQuery;
    }
});



module.exports = Tables;