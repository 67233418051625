let $ = require('jquery');
let _ = require('underscore');
let Backbone = require('backbone');
let Utils = require('./class/Utils');

let MSiS = require('./model/SearchInSearch');
let MLine = require('./model/Line');
let CLines = require('./collection/Lines');
let MTable = require('./model/Table');
let CTables = require('./collection/Tables');

let VCard = require('./view/Card');



(function() {

    class DetailedSearch {
        // selector - html селектор, куда будет помешен плагин
        //
        // parameters   1) Группы могут быть только для корневых параметров ( для подпараметров группы не предусмотрены)
        //              2) Группы внутри группы быть не могут
        //
        // funSearch(searchQuery, action) - функция, выполняющаяся при поиске (по нажатию кнопки "Поиск" или клику найденного по строке или
        //             из js search()). В функцию приходит 2 параметра action может принимать 2 варианта:
        //             'main' - основной поиск по кнопке "Поиск" или через js search()
        //             'line' - поиск по клику по строке (кол. найденного)
        //              searchQuery - массив объектов сформированный плагином для поиска. В случае типа line будет
        //              просто объект, соответствующий 1 строке. Пример:
        //[
        //    [
        //        {
        //            cid: "c19",
        //            type: "exclude",
        //            idParam: {title: "ГОСТы"},
        //            info: "gost",
        //            exclude: "er"
        //        },
        //        {
        //            cid: "c20",
        //            type: "diapason",
        //            idParam: {title: "Круг", subTitle: "d,mm"},
        //            info: "diameter",
        //            infoParent: {ppn: 23, ii: "ddd"},
        //            start: "111",
        //            end: "222"
        //        },
        //        {
        //            cid: "c21",
        //            type: "foryear",
        //            idParam: {title: "Дата сертификата"},
        //            info: "certificateNumber",
        //            start: "20.02.2019",
        //            end: "20.02.2020"
        //        }
        //    ],
        //    [
        //        {
        //            cid: "c24",
        //            type: "value",
        //            idParam: {title: "Тип сертификата"},
        //            info: "certificateType",
        //            value: "2"
        //        }
        //    ]
        //]
        //
        // options:  (необязательный параметр)
        //    isSearchByLine   - активировать ли возможность поиска по строкам. Если информация о кол найденного не указана, ничего не выводится (поиск невозможен).
        //            true - (по умолчанию) можно кликать по кол найденного в строке, выполняется функция поиска
        //            false - просто информация о кол найден. (некликабельно)
        //    numberForSearchInSearch - количество найденных элементов с которых возможен режим поиска среди найденного
        //    isUniversalSearch - (true/false) включен ли уневирсальный режим поиска (по умолчанию да). При уневерсальном режиме
        //                            связь между строками таблиц ИЛИ и есть режим поиск среди найденного с лог связью И
        //                            (стандартный режим). При упрощенном режиме связь между строками таблицы И, все в
        //                            рамках ОДНОЙ таблице (поиск среди найденных невозможен).
        //    disabled: false  - полная блокировка интерфейса
        constructor(selector, parameters, funSearch, options) {
            this.VERSION = '0.3.0';
            this.parameters = parameters;
            this._activationButDropdownMenu(selector);
            this._inpEnter(selector);
            let defaults = {
                numberForSearchInSearch: 10,
                isSearchByLine: true,
                isUniversalSearch: true,
                disabled: false
            };
            let opt = {};
            Object.assign(opt, defaults, _.isObject(options) ? options : {});
            // --- проверяем parameters на уникальные title ---
            let parametersWithoutGroup = [];
            _.each(parameters, function(param) {
                if (param.optgroupLabel) parametersWithoutGroup = parametersWithoutGroup.concat(param.parameters);
                else parametersWithoutGroup.push(param);
            }, this);
            let doubles = _getDoublesTitle(parametersWithoutGroup);
            _.each(parametersWithoutGroup, function(param) {
                if (param.subParameters) doubles = doubles.concat(_getDoublesTitle(param.subParameters));
            }, this);
            // ------
            if (doubles.length > 0) {
                console.log('[DetailedSearch] В parameters значение свойств title должно быть уникальным. Список неуникальных title: ');
                console.log(doubles);
                $(selector).html(`<div style="background-color: #ffdfdf; text-align: center; border: 1px solid #992020;
                                  color: #992020; padding: 10px;">Ошибка при инициализации плагина.</div>`);
            } else {
                parameters.mSiS = new MSiS({ numElForStartSiS: opt.numberForSearchInSearch });
                parameters.options = opt;
                parameters.funSearch = funSearch;
                parameters.selector = selector;
                let mLine = new MLine({}, parameters);
                let cLines = new CLines([mLine], parameters);
                let mTable = new MTable({lines: cLines}, parameters);
                let cTables = new CTables([mTable], parameters);
                parameters.cTables = cTables;
                this.viewCard = new VCard({collection: cTables}, parameters);
                $(selector).html(this.viewCard.render().el);
            }

            function _getDoublesTitle(parameters) {
                let doubles = [];
                _.each(parameters, function(param) {
                    let dbl = _.where(parameters, {title: param.title});
                    if ((dbl.length > 1) && (doubles.indexOf(param.title) === -1)) doubles.push(param.title);
                }, this);
                return doubles;
            }
        }



        // запускаем поиск с помошью js. То же что нажать на кнопку "Поиск" (от нее продолжить цепочку нельзя)
        search() {
            this.viewCard.mainSearch();
        }



        // Останавливаем поиск.
        // numberFound - общее количество найденных элементов
        // При вызове функции без параметра не изменяется предыдущее значение numberFoundForTable, такое поведение используется при
        // запросе по строке, чтобы оставить общее число найденных элементов без изменений. Запрос по всему фильтру
        // как правило используется с numberFoundForTable, без этого значения невозможно будет осуществить "поиск в поиске".
        searchEnd(numberFound) {
            this.viewCard.collection.searchStop(numberFound);
            return this;
        }



        // Задаем поисковый шаблон patern для плагина. Пример patern для универсального режима (массив с массивами таблиц, внутри которых объекты строк):
        //[
        //    [
        //        {
        //            cid: "c19",
        //            type: "exclude",
        //            idParam: {title: "ГОСТы"},
        //            info: "gost",
        //            exclude: "er"
        //        },
        //        {
        //            cid: "c20",
        //            type: "diapason",
        //            idParam: {title: "Круг", subTitle: "d,mm"},
        //            info: "diameter",
        //            infoParent: {ppn: 23, ii: "ddd"},
        //            start: "111",
        //            end: "222"
        //        },
        //        {
        //            cid: "c21",
        //            type: "foryear",
        //            idParam: {title: "Дата сертификата"},
        //            info: "certificateNumber",
        //            start: "20.02.2019",
        //            end: "20.02.2020"
        //        }
        //    ],
        //    [
        //        {
        //            cid: "c24",
        //            type: "value",
        //            idParam: {title: "Тип сертификата"},
        //            info: "certificateType",
        //            value: "2"
        //        }
        //    ]
        //]
        // Для НЕ универсального поиска (массив объектов строк):
        //[
        //    {
        //        cid: "c24",
        //        type: "value",
        //        idParam: {title: "Тип сертификата"},
        //        info: "certificateType",
        //        value: "2"
        //    }
        //]
        setPatern(patern) {
            let isUniversalSearch = this.parameters.options.isUniversalSearch;
            let forUS = patern && patern[0] && patern[0][0] && patern[0][0].idParam;
            let forNotUS = patern && patern[0] && patern[0].idParam;

            // находим в массиве входных параметров конкретные параметры, соответсвующие
            // linePatern и генерируем объект атрибутов для модели Line
            function genLine(linePatern) {
                let line = {identifier: linePatern.type};
                let rootTitle = _.findWhere(this.parameters, {title: linePatern.idParam.title});
                if (rootTitle) line.parameter = rootTitle;
                else {
                    _.each(this.parameters, function(param) {
                        if (param.optgroupLabel) {
                            if (!rootTitle) rootTitle = _.findWhere(param.parameters, {title: linePatern.idParam.title});
                        }
                    }, this);
                    line.parameter = rootTitle;
                }
                if (line.parameter.subParameters) {
                    line.subparameter = _.findWhere(line.parameter.subParameters, {title: linePatern.idParam.subTitle});
                }
                if (linePatern.exclude) line.value = linePatern.exclude;
                if (linePatern.value) line.value = linePatern.value;
                if (linePatern.start) line.start = linePatern.start;
                if (linePatern.end) line.end = linePatern.end;
                return line;
            }
            let funGenLine = genLine.bind(this);

            function genTable(tablePatern, iTable) {
                let lines = [];
                _.each(tablePatern, function(linePatern) {
                    lines.push(new MLine(funGenLine(linePatern), this.parameters));
                }, this);
                let isLast = isUniversalSearch ? patern.length == (iTable+1) : true;
                if (isLast) lines.push(new MLine({}, this.parameters));
                let isDisabled = this.parameters.options.disabled;

                let cLines = new CLines(lines, this.parameters);
                cLines.disabled = isLast ? isDisabled : true;
                return new MTable({
                                    disabled: isLast ? isDisabled : true,
                                    lines: cLines
                                }, this.parameters);
            }
            let funGenTable = genTable.bind(this);

            // минимальная проверка на валидность данных
            if (forUS && isUniversalSearch) {
                let tables = [];
                _.each(patern, function(tablePatern, iTable) {
                    tables.push(funGenTable(tablePatern, iTable));
                }, this);
                this.viewCard.collection.reset(tables);
            } else if (forNotUS && !isUniversalSearch) {
                this.viewCard.collection.reset([ funGenTable(patern) ]);
            } else {
                console.log('[DetailedSearch] В функцию setSearchPatern(patern) переданы неправильные данные.');
                $(this.parameters.selector).html(`<div style="background-color: #ffdfdf; text-align: center; border: 1px solid #992020;
                                  color: #992020; padding: 10px;">Ошибка при передаче параметров в плагин.</div>`);
            }
            return this;
        }



        // Блокируем весь интерфейс плагина
        // isDisabled - true/false
        setDisabled(isDisabled) {
            this.parameters.options.disabled = !!isDisabled;
            let lastTable = this.viewCard.collection.last();
            lastTable.set('disabled', !!isDisabled);
            this.viewCard.render(); // если не поставить, то кнопка "Поиск" и "Сброс поискового запроса" не разблокируются
            return this;
        }



        // Указываем сколько найдено элементов по строк(е/ам). Можно передать объект, тогда установится значение для
        // конкретной строки, можно передать массив объектов. Пример объекта:
        // { cid: '342', numberFound: 33 }
        // cid - уникальный id строки, получаем из функции funSearch
        // если в numberFound передать false - то информация о кол найденных по данной строке исчезнет
        setNumberSearchForLines(dataNumberSearch) {
            let isError = false;
            if (_.isObject(dataNumberSearch) && !_.isArray(dataNumberSearch)) dataNumberSearch = [dataNumberSearch];
            if (_.isArray(dataNumberSearch)) {
                _.each(dataNumberSearch, function(ns) {
                    if (_.isUndefined(ns.cid) || _.isUndefined(ns.numberFound)) isError = true;
                }, this);
            } else isError = true;
            if (!isError) {
                _.each(dataNumberSearch, function(ns) {
                    this.viewCard.collection.each(function(table) {
                        let line = table.get('lines').get(ns.cid);
                        if (line) line.set('numberFound', ns.numberFound);
                    }, this);
                }, this);
            } else console.log('[DetailedSearch] Задан неверный параметр для функции setNumberSearchForLines(dataNumberSearch)');
            return this;
        }



        // Указываем сколько найденно элементов для таблиц(ы). Можно передать объект, тогда установится значение для
        // конкретной таблицы, можно передать массив объектов. Пример объекта:
        // { i: 0, numberFound: 33 }
        // i - это порядковый номер таблицы, начиная с 0
        setNumberSearchForBlock(dataNumberSearch) {
            let isError = false;
            if (_.isObject(dataNumberSearch) && !_.isArray(dataNumberSearch)) dataNumberSearch = [dataNumberSearch];
            if (_.isArray(dataNumberSearch)) {
                _.each(dataNumberSearch, function(ns) {
                    if (_.isUndefined(ns.i) || _.isUndefined(ns.numberFound)) isError = true;
                }, this);
            } else isError = true;
            if (!isError) {
                _.each(dataNumberSearch, function(ns) {
                    let table = this.viewCard.collection.at(ns.i);
                    if (table) table.set('numberFound', ns.numberFound);
                }, this);
                this.viewCard.render();
            } else console.log('[DetailedSearch] Задан неверный параметр для функции setNumberSearchForTable(dataNumberSearch)');
            return this;
        }



        // --------------------- вспомогательные фуункции ---------------------
        _activationButDropdownMenu(selector) {
            $(document).on('mouseup', function () {
                let clickEl = $(event.target);
                let parentClickEl = clickEl.parent();
                if (!(clickEl.hasClass('ds-dropdown-btn-for-logics') || parentClickEl.hasClass('ds-dropdown-btn-for-logics'))) {
                    $(selector).find('.ds-btn-group').removeClass('ds-ddm-open');
                }
            });
            $(selector).on('click', '.ds-dropdown-btn-for-logics', function() {
                let isDisabled = $(this).is('[disabled=disabled]');
                let div = $(this).closest('.ds-btn-group');
                if (!isDisabled) {
                    let isOpen = div.hasClass('ds-ddm-open');
                    $(selector).find('.ds-btn-group').removeClass('ds-ddm-open');
                    if (!isOpen) {
                        div.addClass('ds-ddm-open');
                    }
                }
            });
        }

        _inpEnter(selector) {
            let _this = this;
            $(selector).on('keyup', '.ds-inp', function(event){
                if(event.keyCode == 13){
                    _this.viewCard.mainSearch();
                }
            });
        }

    }



    module.exports = DetailedSearch;
})();