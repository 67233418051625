let Backbone = require('backbone');
require('./../class/Ribs');
let CLines = require('./../collection/Lines');



let Table = Backbone.Ribs.Model.extend({
    defaults: {
        selected: false,
        disabled: false,
        numberFound: undefined,
        lines: undefined,        // коллекция строк
        blockingSiS: true        // не показываем кнопку "поиск в найденном"
    },

    initialize: function (attributes, parameters) {
        this.parameters = parameters;
    }

});


module.exports = Table;