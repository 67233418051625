let $ = require('jquery');
let _ = require('underscore');
let Backbone = require('backbone');
let Utils = require('./../class/Utils');
let MLine = require('./../model/Line');
let CLines = require('./../collection/Lines');
let MTable = require('./../model/Table');
let VTable = require('./Table');
var TemCard = require('./../template/card.tpl');



// Главное отображение
let Card = Backbone.View.extend({
    tagName: 'div',
    className: 'detailed-search-card',

    initialize: function(collection, parameters) {
        this.funSearch = parameters.funSearch;
        this.parameters = parameters;
        this.listenTo(this.collection, 'reset', this.collectionAddOrReset);
        this.listenTo(this.collection, 'add', this.collectionAddOrReset);
        this.listenTo(this.collection, 'change', this.collectionChange);
        this.lastTableDisabled = this.collection.last().get('disabled');
        this.tables = [];
    },


    events: {
        'click .bbds-btn-main-search': 'mainSearch',
        'click .bbds-btn-full-reset': 'fullReset'
    },


    collectionAddOrReset: function() {
        let lnCol = this.collection.length-1;
        this.collection.each(function(mTbl, index) {
            // --- блочим все таблицы, кроме последней ---
            if(lnCol !== index) mTbl.set('disabled', true);
        });
        this.render();
    },


    collectionChange:function() {
        if (this.lastTableDisabled !== this.collection.last().get('disabled')) {
            this.lastTableDisabled = this.collection.last().get('disabled');
            this.render();
        }
    },


    // Формируем поисковый запрос, который отдаем пользователю
    mainSearch: function() {
        if (_.isFunction(this.funSearch)) {
            this.collection.last().set('disabled', true);
            this.parameters.mSiS.set('resetSelection', this.parameters.mSiS.get('resetSelection') + 1); // убераем выделение
            this.parameters.mSiS.set('trackChangesInLine', false);
            let searchQuery = this.collection.generateSearchObject();
            // В упрощённом случае, когда между строками связь И может быть только одна таблица.
            if (!this.parameters.options.isUniversalSearch) {
                if (searchQuery.length !== 1) {
                    console.log('[DetailedSearch] Ошибка при генерации searchQuery.');
                    $(this.parameters.selector).html(`<div style="background-color: #ffdfdf; text-align: center;
                          border: 1px solid #992020; color: #992020; padding: 10px;">Внутренняя ошибка плагина.</div>`);
                    searchQuery = 'error';
                } else searchQuery = searchQuery[0];
            }
            this.funSearch(searchQuery, 'main');
        }
    },


    fullReset: function() {
        let mLine = new MLine({}, this.parameters);
        let cLines = new CLines([mLine], this.parameters);
        let mTable = new MTable({lines: cLines}, this.parameters);
        this.collection.reset([mTable]);
    },


    render: function() {
        let globalDisabled = this.parameters.options.disabled;
        this.$el.html(Utils.template(TemCard, {
            disabledLastTable: this.collection.last().get('disabled'),
            globalDisabled: globalDisabled
        }));
        this.initViewsTables();
        _.each(this.tables, function(tbl, i) {
            let table = tbl.view.render().$el;
            if (i > 0) {
                let bufNumberFound = this.tables[i-1].model.get('numberFound'); // предыдущее значение
                bufNumberFound = bufNumberFound ? ' ' + bufNumberFound : '';
                let subtitle = $('<div/>', {class: 'ds-subtitle'}).html(`Поиск среди найденных${ bufNumberFound }:`);
                if (globalDisabled) subtitle.css({color: '#888'});
                this.$('.bbds-tables').append(subtitle);
            }
            this.$('.bbds-tables').append(table);
        }, this);
        return this;
    },


    initViewsTables: function() {
        _.each(this.tables, function(tbl) {
            tbl.view.stopListening();
        }, this);
        this.tables = [];
        this.collection.each(function(mTbl) {
            this.tables.push({
                view: new VTable({model: mTbl}),
                model: mTbl
            });
        }, this);
    }

});



module.exports = Card;