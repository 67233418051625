let Backbone = require('backbone');
let _ = require('underscore');


let SearchInSearch = Backbone.Model.extend({
    defaults: {
        blocking: true, // true - блокировать кнопку "поиск среди найденных"
        numberChanges: 0,
        numElForStartSiS: null,
        numberFound: null,
        trackChangesInLine: true, // Когда начинаем отслеживать, при любом изменении - удалять значения в ячейке "найдено",
        resetSelection: 0 // когда меняется значение - сбрасываем выделение у всех строк (поиск по строке, голубой фон)
    },

    initialize: function () {
        this.listenTo(this, 'change:numberChanges', this.changeNumberChanges);
        this.listenTo(this, 'change:numberFound', this.changeNumberFound);
    },

    changeNumberChanges: function () {
        if (this.get('trackChangesInLine')) {
            this.set('blocking', true);
        }
    },

    changeNumberFound: function () {
        let numFound = this.get('numberFound');
        let minNumFound = this.get('numElForStartSiS');
        if (!_.isNull(numFound)) {
            this.set('blocking', numFound < minNumFound);
        } else this.set('blocking', true);
    }
});


module.exports = SearchInSearch;