let $ = require('jquery');
let _ = require('underscore');
let Backbone = require('backbone');
let Utils = require('./../class/Utils');
let ClassScope = require('./../class/Scope');
var TemScope = require('./../template/scope.tpl');




let Scope = Backbone.View.extend({
    initialize: function() {
        this.classScope = new ClassScope();
        this.listenTo(this.model, 'change:parameter', this.changeParam);
        this.listenTo(this.model, 'change:subparameter', this.changeParam);
        this.listenTo(this.model, 'change:identifier', this.render);
        this.listenTo(this.model, 'change:value', this.render);
        this.listenTo(this.model, 'change:start', this.render);
        this.listenTo(this.model, 'change:end', this.render);
    },


    changeParam: function() {
        let actualParam = this.model.getActualParam();
        if (actualParam.type) {
            this.model.set('identifier', 'value');
            this.model.set('value', undefined);
            this.model.set('start', undefined);
            this.model.set('end', undefined);
        }
        this.render();
    },


    events: {
        'click .bbds-dropdown-item': 'changeDropdownItem',
        'change .bbds-value': 'inpChangeVal',
        'change .bbds-from': 'inpChangeFrom',
        'change .bbds-to': 'inpChangeTo',
        'change .bbds-select': 'selectChange'
    },


    inpChangeVal: function() {
        let val = Utils.trim(this.$('.bbds-value').val());
        this.model.set('value', val);
    },


    inpChangeFrom: function() {
        let start = Utils.trim(this.$('.bbds-from').val());
        this.model.set('start', start);
    },


    inpChangeTo: function() {
        let end = Utils.trim(this.$('.bbds-to').val());
        this.model.set('end', end);
    },


    changeDropdownItem: function(event) {
        let identifier = $(event.target).data('identifier');
        this.model.set('identifier', identifier);
        this.model.set('value', undefined);
        this.model.set('start', undefined);
        this.model.set('end', undefined);
        if (identifier == 'forday') {
            this.model.set('value', Utils.dateFormat(new Date(), 'd.m.y'));
        }
        if (identifier == 'forweek') {
            this.model.set('start', Utils.dateFormat(Utils.getDateFromCurrentDate(-7), 'd.m.y'));
            this.model.set('end', Utils.dateFormat(new Date(), 'd.m.y'));
        }
        if (identifier == 'formonth') {
            this.model.set('start', Utils.dateFormat(Utils.getDateFromCurrentDate(-30), 'd.m.y'));
            this.model.set('end', Utils.dateFormat(new Date(), 'd.m.y'));
        }
        if (identifier == 'foryear') {
            this.model.set('start', Utils.dateFormat(Utils.getDateFromCurrentDate(-365), 'd.m.y'));
            this.model.set('end', Utils.dateFormat(new Date(), 'd.m.y'));
        }
    },


    selectChange: function(event) {
        let val = this.$('.bbds-select').val();
        this.model.set('value', val);
    },


    render: function() {
        let actualParam = this.model.getActualParam();
        let identifier = this.model.get('identifier');
        if (actualParam && actualParam.type) {
            this.$el.html(Utils.template(TemScope, {
                type: actualParam.type,
                scope: this.classScope.getAll(actualParam.type),
                dataScope: actualParam.type && identifier ? this.classScope.getScope(actualParam.type, identifier) : false,
                select: actualParam.select,
                identifier: identifier,
                value: this.model.get('value'),
                start: this.model.get('start'),
                end: this.model.get('end'),
                disabledTable: this.model.collection.disabled
            }));
        } else this.$el.html('');
        Utils.date(this.$el.find('.bbds-flatpickr'));
        return this;
    }

});


module.exports = Scope;