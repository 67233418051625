let $ = require('jquery');
let _ = require('underscore');
let Backbone = require('backbone');
let Utils = require('./../class/Utils');
let VLine = require('./Line');
let MLine = require('./../model/Line');
let CLines = require('./../collection/Lines');
let MTable = require('./../model/Table');
let TemTable = require('./../template/table.tpl');




let Table = Backbone.View.extend({
    tagName: 'table',
    className: 'ds-tbl-search',

    initialize: function() {
        let cLines = this.model.get('lines');
        this.listenTo(cLines, 'add', this.render);
        this.listenTo(cLines, 'remove', this.render);
        this.listenTo(this.model, 'change:disabled', this.changeDisabled);
        this.listenTo(this.model, 'change:numberFound', this.render);
        this.listenTo(this.model.parameters.mSiS, 'change:blocking', this.isBlockingSiS);
    },


    changeDisabled: function() {
        this.model.get('lines').disabled = this.model.get('disabled');
        this.render();
    },



    isBlockingSiS: function() {
        let disabled = this.model.get('disabled');
        let blocking = this.model.parameters.mSiS.get('blocking');
        let notUniversalSearch = !this.model.parameters.options.isUniversalSearch;
        this.model.set('blockingSiS', disabled || blocking || notUniversalSearch);
        this.render();
    },


    events: {
        'click .ds-btn-search-among-found': 'searchAmongFound'
    },


    searchAmongFound: function() {
        let mLine = new MLine({}, this.model.parameters);
        let cLines = new CLines([mLine], this.model.parameters);
        let mTable = new MTable({lines: cLines}, this.model.parameters);
        this.model.collection.add(mTable);
        this.model.collection.generateSearchObject(); // удаляем пустые строчки
    },


    render: function() {
       if ( !_.isUndefined(this.model.collection) ) { // Костыль, значит не все вьюхи уничтожаются
           let isLastTable = this.model.collection.last().cid == this.model.cid;
           let numberLineInLastTable = this.model.collection.last().get('lines').length;
           let numberFound = this.model.get('numberFound');
           let isFirst = this.model.collection.indexOf(this.model) === 0;
           // Показывать "Всего найдено: N" в случае:
           // 1) Это должна быть последняя поисковая таблица
           // 2) (1) И        после поиска (если найдено 0 или больше)
           // 3) (1) И (2) И  в последней поисковой таблицы нет строк фильтра
           let isDrawNumberFound = isLastTable && (numberLineInLastTable == 1 || numberFound || numberFound === 0);

           if (isFirst) this.$el.addClass('tbl-first');
           if (!this.model.get('disabled')) this.$el.removeClass('disabled');
           else this.$el.addClass('disabled');

           this.$el.html(Utils.template(TemTable, {
               blockingSiS: this.model.get('blockingSiS'),
               disabled: this.model.get('disabled'),
               numberFound: numberFound,
               isDrawNumberFound: isDrawNumberFound
           }));

           this.model.get('lines').each(function (mLine, key) {
               let vLine = new VLine({model: mLine});
               this.$('tbody').append(vLine.render().$el);
           }, this);
       }
        return this;
    }

});



module.exports = Table;