let $ = require('jquery');
let _ = require('underscore');
let Backbone = require('backbone');
let Utils = require('./../class/Utils');
let MLine = require('./../model/Line');
let VParameter = require('./Parameter');
let VScope = require('./Scope');
var TemLine = require('./../template/line.tpl');




let Line = Backbone.View.extend({
    tagName: 'tr',

    initialize: function() {
        this.listenTo(this.model, 'change:parameter', this.changeParam);
        this.listenTo(this.model, 'change:subparameter', this.changeForSiS);
        this.listenTo(this.model, 'change:identifier', this.changeForSiS);
        this.listenTo(this.model, 'change:value', this.changeForSiS);
        this.listenTo(this.model, 'change:start', this.changeForSiS);
        this.listenTo(this.model, 'change:end', this.changeForSiS);
        this.listenTo(this.model, 'change:numberFound', this.changeNumberFound);
        this.listenTo(this.model, 'change:selected', this.render);

        this.vParameter = new VParameter({model: this.model});
        this.vScope = new VScope({model: this.model});
    },


    changeForSiS: function () {
        if (this.model.parameters.mSiS.get('trackChangesInLine')) {
            this.model.set('selected', false);
            this.model.set('numberFound', undefined);
            this.$('.bbds-td-found').html('');
            // Убераем кнопку "Поиск в поиске"
            let numberChanges = this.model.parameters.mSiS.get('numberChanges') + 1;
            this.model.parameters.mSiS.set('numberChanges', numberChanges);
        }
    },


    changeNumberFound: function () {
        // Если false то обнуляем и скрываем информацию о количестве найденных по строке
        if (this.model.get('numberFound') === false) {
            this.model.set('selected', false);
            this.model.set('numberFound', undefined);
            this.$('.bbds-td-found').html('');
        }
        this.render();
    },


    changeParam: function() {
        this.changeForSiS();
        if (!_.isUndefined(this.model.get('parameter'))) {
            this.$('.bbds-td-close').html(`<a class="ds-btn-icon-danger bbds-delete-line"><i class="material-icons">close</i></a>`);
            let index = this.model.collection.indexOf(this.model);
            if (index === this.model.collection.length - 1) {
                this.model.collection.add(new MLine({}, this.model.parameters));
            }
        }
    },


    events: {
        'click .bbds-delete-line': 'deleteLine',
        'click .ds-number-found-for-line-click': 'numberFoundForLine'
    },


    numberFoundForLine: function() {
        // убераем выделение
        this.model.parameters.mSiS.set('resetSelection', this.model.parameters.mSiS.get('resetSelection') + 1);
        this.model.set('selected', true);
        if (_.isFunction(this.model.parameters.funSearch)) {
            this.model.parameters.mSiS.set('trackChangesInLine', false);
            // ============================ из collection/Tables.js
            let mLn = this.model;
            let resultLine = { cid: mLn.cid };
            let actualParam = mLn.getActualParam();
            if (actualParam.info) resultLine.info = actualParam.info;
            if (actualParam.infoParent) resultLine.infoParent = actualParam.infoParent;
            let identifier = mLn.get('identifier');
            let value = mLn.get('value');
            let start = mLn.get('start');
            let end = mLn.get('end');
            if (resultLine && identifier) {
                switch (identifier) {
                    case 'value':
                        if (value) resultLine.value = value;
                        else resultLine = false;
                        break;
                    case 'exclude':
                        if (value) resultLine.exclude = value;
                        else resultLine = false;
                        break;
                    case 'from':
                        if (start) resultLine.start = start;
                        else resultLine = false;
                        break;
                    case 'to':
                        if (end) resultLine.end = end;
                        else resultLine = false;
                        break;
                    case 'diapason':
                        setDiapason(start, end, resultLine);
                        break;
                    case 'forday':
                        if (value) resultLine.value = value;
                        else resultLine = false;
                        break;
                    case 'forweek':
                        setDiapason(start, end, resultLine);
                        break;
                    case 'formonth':
                        setDiapason(start, end, resultLine);
                        break;
                    case 'foryear':
                        setDiapason(start, end, resultLine);
                        break;
                    default: resultLine = false;
                }
            } else resultLine = false;

            function setDiapason(start, end, resultLine) {
                if (start || end) {
                    if (start) resultLine.start = start;
                    if (end) resultLine.end = end;
                } else resultLine = false;
            }
            // ============================
            this.model.parameters.cTables.last().set('disabled', true);
            this.model.parameters.funSearch(resultLine, 'line');
            this.render();
        }
    },


    deleteLine: function() {

        console.log('deleteLine');

        let disabledTable = this.model.collection.disabled;
        if (!disabledTable) this.model.collection.remove(this.model);
    },


    render: function() {
        let isNewLine = _.isUndefined(this.model.get('parameter'));
        this.$el.html(Utils.template(TemLine, {
            isFirst: this.model.collection.indexOf(this.model) === 0,
            isNewLine: isNewLine,
            selected: this.model.get('selected'),
            numberFound: this.model.get('numberFound'),
            disabledTable: this.model.collection.disabled,
            numberFoundLineIsActive: this.model.parameters.options.isSearchByLine,
            union: this.model.parameters.options.isUniversalSearch ? 'ИЛИ' : 'И'
        }));
        this.$('.bbds-logic-parameter').html(this.vParameter.render().el);
        this.$('.bbds-logic-scope').html(this.vScope.render().el);
        return this;
    }

});



module.exports = Line;