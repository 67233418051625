let _ = require('underscore');
let Flatpickr = require('flatpickr');
let FRussian = require('./../../../node_modules/flatpickr/dist/l10n/ru').default.ru;



let Utils = {
    template: function (templ, data) {
        var tem = _.template(templ);
        return tem(data);
    },


    guid: function () {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    },


    trim: str => str.replace(/ {1,}/g, " ").trim(),


    date: function(selector, defaultDate) {
        let options = {
            locale: FRussian,
            dateFormat: "d.m.Y"
        };
        if (defaultDate) options.defaultDate = defaultDate;
        if (typeof selector === 'string') Flatpickr(selector, options);
        else if (selector.length) {
            _.each(selector, function(input, i) {
                Flatpickr(selector.get(i), options);
            });
        }
    },


    // date - объект Date
    // settings - строка, описывающая формат вывода даты, где:
    // d - день       m - месяц       y - год
    // h - часы       i - минуты      s - секунды
    // Пример:
    // 'd.m.y h:i:s'     или     'y-m-d'     или     'h:i'   и т.д.
    dateFormat: function (date, settings) {
        let day = date.getDate().toString().length === 1 ? '0' + date.getDate() : date.getDate();
        let month = (date.getMonth() + 1).toString();
        month = month.length === 1 ? '0' + month : month;
        let year = date.getFullYear().toString();
        let hours = date.getHours().toString().length === 1 ? '0' + date.getHours() : date.getHours();
        let minutes = date.getMinutes().toString().length === 1 ? '0' + date.getMinutes() : date.getMinutes();
        let seconds = date.getSeconds().toString().length === 1 ? '0' + date.getSeconds() : date.getSeconds();
        return  settings.replace('d', day)
            .replace('m', month)
            .replace('y', year)
            .replace('h', hours)
            .replace('i', minutes)
            .replace('s', seconds);
    },


    // Добавляем или отнимаем (целое) количество дней от текущего момента.
    // В зависимости от того отрицательное или положительное numberDay.
    getDateFromCurrentDate: function (numberDay) {
        let millisecondsNow = new Date().getTime();
        let millisecondsDay = 3600 * 24 * 1000;
        return new Date(millisecondsNow + millisecondsDay * numberDay);
    }

};



module.exports = Utils;